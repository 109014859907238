@import '../../mixins';

.actions {
  &__content {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: var(--grid-column-gap);
    row-gap: var(--grid-row-gap);

    @include media(#{rem(1200)}) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media(#{rem(520)}) {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 390/200;
    display: flex;

    border-radius: var(--radius-common);
    overflow: hidden;

    @include hover {
      & .actions {
        &__image {
          transform: scale(1.07);
        }
      }
    }

    & picture,
    & img {
      width: 100%;
      height: 100%;
      display: flex;
    }
  }

  &__image {
    width: 100%;
    height: 100%;

    aspect-ratio: 390/200;
    object-fit: cover;
    object-position: center;

    pointer-events: none;
    user-select: none;

    transition: transform var(--animation-timing-long) var(--cubic-bezier);

    @include media(#{rem(1200)}) {
      aspect-ratio: 354/152;
    }
    @include media(#{rem(520)}) {
      aspect-ratio: 290/125;
    }
  }
}
