.actions__content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  -webkit-column-gap: var(--grid-column-gap);
     -moz-column-gap: var(--grid-column-gap);
          column-gap: var(--grid-column-gap);
  row-gap: var(--grid-row-gap); }
  @media screen and (max-width: 75rem) {
    .actions__content {
      grid-template-columns: repeat(2, 1fr); } }
  @media screen and (max-width: 32.5rem) {
    .actions__content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column; } }

.actions__item {
  position: relative;
  width: 100%;
  height: auto;
  aspect-ratio: 390/200;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border-radius: var(--radius-common);
  overflow: hidden; }
  @media (any-hover: hover) {
    .actions__item:hover .actions__image {
      -webkit-transform: scale(1.07);
          -ms-transform: scale(1.07);
              transform: scale(1.07); } }
  .actions__item picture,
  .actions__item img {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }

.actions__image {
  width: 100%;
  height: 100%;
  aspect-ratio: 390/200;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-transition: -webkit-transform var(--animation-timing-long) var(--cubic-bezier);
  transition: -webkit-transform var(--animation-timing-long) var(--cubic-bezier);
  -o-transition: transform var(--animation-timing-long) var(--cubic-bezier);
  transition: transform var(--animation-timing-long) var(--cubic-bezier);
  transition: transform var(--animation-timing-long) var(--cubic-bezier), -webkit-transform var(--animation-timing-long) var(--cubic-bezier); }
  @media screen and (max-width: 75rem) {
    .actions__image {
      aspect-ratio: 354/152; } }
  @media screen and (max-width: 32.5rem) {
    .actions__image {
      aspect-ratio: 290/125; } }
